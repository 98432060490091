<template>
    <div class="content-index" style="padding:20px; overflow: auto;">
        <div
                class="flex-row"
                style="margin-bottom:10px;cursor:pointer"
                @click="back"
        >
            <i class="el-icon-back" style="font-size:18px;color:#000"></i>
            <span style="padding-left:10px"
            ><span style="color:#8A9099">库存查询</span> / 出入库明细</span
            >
        </div>
        <div class="drawer-content">
            <div class="search">
                <Search
                        ref="search"
                        :search-data="searchData"
                        @searchChange="searchChange"
                />
            </div>
            <el-tabs v-model="formData.recordType" @tab-click="handleClick">
                <el-tab-pane label="全部" name="0">
                    <div
                            v-loading="tableLoading"
                            class="table-box"
                            v-if="formData.recordType == '0'"
                    >
                        <Table
                                :current-value="formData.current"
                                :header-style="headerStyle"
                                :height="''"
                                :orientation="'center'"
                                :serial-number="true"
                                :table-data="tableData"
                                :title-list="titleListData"
                                :total="total"
                                @handleCurrentChange="handleCurrentChange"
                        />
                    </div>
                </el-tab-pane>
                <el-tab-pane label="入库记录" name="1">
                    <div
                            v-loading="tableLoading"
                            class="table-box"
                            v-if="formData.recordType == '1'"
                    >
                        <Table
                                :current-value="formData.current"
                                :header-style="headerStyle"
                                :height="''"
                                :orientation="'center'"
                                :serial-number="true"
                                :table-data="tableData"
                                :title-list="titleListData"
                                :total="total"
                                @handleCurrentChange="handleCurrentChange"
                        />
                    </div>
                </el-tab-pane>
                <el-tab-pane label="出库记录" name="2">
                    <div
                            v-loading="tableLoading"
                            class="table-box"
                            v-if="formData.recordType == '2'"
                    >
                        <Table
                                :current-value="formData.current"
                                :header-style="headerStyle"
                                :height="''"
                                :orientation="'center'"
                                :serial-number="true"
                                :table-data="tableData"
                                :title-list="titleListData"
                                :total="total"
                                @handleCurrentChange="handleCurrentChange"
                        />
                    </div>
                </el-tab-pane>
            </el-tabs>
            <div class="table-box"></div>
        </div>
    </div>
</template>

<script>
import Table from "@/components/table2";
import Search from "@/components/search";
import {getInventoryRecordDetailPage} from "@/api/commodity";

export default {
    components: {
        Table,
        Search,
    },
    props: {},
    data() {
        return {
            headerStyle: {
                background: "#f5f7fa",
                height: "50px",
            },
            searchData: [
                {
                    prop: "createTime",
                    label: "查询时间",
                    type: "DateTime",
                    labelWidth: "70px",
                },
                {
                    labelWidth: "20px",
                    typeList: [
                        {
                            name: "查询",
                            code: "search",
                            type: "primary",
                        },
                        {
                            name: "重置",
                            code: "reset",
                        },
                    ],
                },
            ],
            formData: {current: 1, size: 10, recordType: "0", operateType: 2, beginTime: '', endTime: ''},
            total: 0,
            tableData: [],
            titleListData: [
                {
                    prop: "goodsName",
                    label: "商品名称",
                    width: 170,
                },
                {
                    prop: "orderGoodsUnit",
                    label: "单位",
                },
                {
                    prop: "recordNumber",
                    label: "单号",
                    width: 120,
                },
                {
                    prop: "inventoryTypeDesc",
                    label: "出入库类型",
                    width: 100,
                },
                {
                    prop: "num",
                    label: "出入库数量",
                    width: 100,
                },
                {
                    prop: "surplusInventoryNum",
                    label: "变动后剩余库存",
                    width: 150,
                },
                {
                    prop: "createdTime",
                    label: "变动时间",
                    width: 170,
                },
                {
                    prop: "operateUserName",
                    label: "操作人",
                    width: 120,
                },
            ],
            tableLoading: false,
        };
    },
    created() {
        this.formData.goodsId = this.$route.query.detailsId;
        this.getInventoryRecordDetailList();
    },
    methods: {
        back() {
            this.$router.back();
        },
        // 出入库明细
        getInventoryRecordDetailList() {
            this.tableLoading = true;
            let data = {
                ...this.formData,
                recordType:
                    this.formData.recordType != "0"
                        ? Number(this.formData.recordType)
                        : null,
            };
            getInventoryRecordDetailPage({...data})
                .then(({data}) => {
                    if (data.code == 0) {
                        console.log(data);
                        this.tableData = data.data.records.map((item) => {
                            if (item.recordType == 1) {
                                item.num = "+ " + item.num;
                            } else {
                                item.num = "- " + item.num;
                            }
                            return item;
                        });
                        console.log(this.tableData);
                        this.total = data.data.total;
                    } else {
                        this.$message.error(data.msg);
                    }
                    this.tableLoading = false;
                })
                .catch(() => {
                    this.tableLoading = false;
                });
        },
        // 搜索内容触发
        searchChange({code, formData}) {
            if (code == "search") {
                this.formData.beginTime = formData.createTime && formData.createTime.length != 0
                    ? formData.createTime[0]
                    : '';
                this.formData.endTime = formData.createTime && formData.createTime.length != 0
                    ? formData.createTime[1]
                    : '';
                this.formData = {
                    ...this.formData,
                    current: 1,
                };
            } else if (code == "reset") {
                this.formData = {
                    current: 1,
                    size: 10,
                    recordType: "0",
                    operateType: 2,
                    goodsId: this.$route.query.detailsId,
                    beginTime: '',
                    endTime: ''
                };
            }
            this.getInventoryRecordDetailList();
        },
        // 表格分页触发
        handleCurrentChange(value) {
            this.formData.current = value;
            this.getInventoryRecordDetailList();
        },
        // tabs切换触发
        handleClick(e) {
            this.formData = {
                current: 1,
                size: 10,
                recordType: e.name,
                operateType: 2,
                goodsId: this.$route.query.detailsId,
                goodsName: null,
                beginTime: this.formData.beginTime,
                endTime: this.formData.endTime,
            };
            this.$refs.search.queryInfo("resetform");
            this.getInventoryRecordDetailList();
        },
    },
};
</script>

<style lang="scss" scoped>
.drawer-content {
  padding: 0 20px 20px;
}

.flex-row {
  display: flex;
  align-items: center;
}

.box {
  /*强制文本在一行内显示*/
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

::v-deep .el-table__body td.el-table__cell {
  height: 50px;
}
</style>
